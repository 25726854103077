<template>
  <v-container>
    <CreateAppointmentDialog ref="createAppointmentDialog" @reload="getCases"/>
    <h1>Bills and Records</h1>
    <div v-if="casesCount === 0" class="mt-2">
      <p>There are currently no billing cases matching the search or location</p>
      <v-btn depressed color="primary" href="/cases/active">
        All Cases
      </v-btn>
    </div>
    <div>
      <v-data-table
        :items="cases"
        :headers="headers"
        @click:row="goToCase($event.id)"
        :item-class="rowClasses"
        :server-items-length="casesCount"
        @update:options="getCases"
        :footer-props="{
      'items-per-page-options': [25, 50, 100]
    }"
      >
        <template v-slot:top>
          <search-bar v-on:child-method="updateSearchInput"/>
        </template>
        <template #item.status="{item}">
          {{capitalizeFirstWord(item.status)}}
        </template>
        <template #item.patient_id="{ item }">
          {{ item.patient.first_name }} {{ item.patient.last_name }}
        </template>

        <template #item.location_id="{ item }">
          {{ item.location.name }}
        </template>

        <template #item.nextAppointment="{ item }">
          <div v-if="item.appointments.length > 0" class="d-flex">
            <v-icon v-if="item.discharged" color="error">fas fa-exclamation-circle</v-icon>
            <div class="mt-1 ml-1">{{ formatCarbonDateNoSetTime(item.appointments[0].date) }}</div>
          </div>
          <div v-else><v-icon color="warning">fas fa-exclamation-circle</v-icon> no appointment set</div>
        </template>

        <template #item.created_at="{ item }">
          {{ formatDate(item.created_at) }}
        </template>

        <template #item.actions="{ item }">
          <v-menu>
            <template #activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>fas fa-ellipsis-h</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="$refs.createAppointmentDialog.open(item.id, item.status)">
                <v-list-item-title>Schedule New Appointment</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
import DateMixin from '../../mixins/DateMixin'
import SearchBar from '@/components/FormFields/SearchBar'
import CreateAppointmentDialog from '@/components/Case/Dialogs/Appointments/CreateAppointmentDialog.vue'
import GetCasesMixin from '@/mixins/GetCasesMixin'
import { formatCarbonDateNoSetTime } from '@/js/PatientIntake/functions'

export default {
  name: 'BillsAndRecords',
  components: { SearchBar, CreateAppointmentDialog },
  mixins: [DateMixin, GetCasesMixin],
  data: () => ({
    formatCarbonDateNoSetTime,
    filterParams: {
      status: 'dischargedPage',
      page: '',
      limit: '',
      orderBy: '',
      orderDir: '',
      patient: true,
      location: true,
      scheduledAppointments: true,
      activeLocationId: '',
      search: ''
    },
    headers: [
      { text: 'Status', value: 'status' },
      { text: 'Patient Name', value: 'patient_id' },
      { text: 'Location', value: 'location_id' },
      { text: 'Initial Appointment', value: 'nextAppointment', sortable: false },
      { text: 'Quick Actions', value: 'actions', sortable: false }
    ]
  }),
  methods: {
    rowClasses (item) {
      if (item.dropped) {
        return 'red lighten-5'
      }
    }
  }
}
</script>
